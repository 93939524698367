<template>
    <div>
        <div class="col-md-12" style="text-align: center; padding-top: 15px;">
            <h3>
                Reset Your Password?
            </h3>
            <img src="@/assets/image/sadrobo.png" style="margin-left: 25px;">
        </div>  
        <div style="height: 40vh;">
            <div class="form-group col-md-12" style="text-align: center;">
                <input type="password" style="width: 300px; display: inline;" class="form-control" placeholder="Password" v-model="resetObj.password">
            </div> 
            <div class="form-group col-md-12" style="text-align: center;">
                <input @input="validatePassword(pass)" v-model="pass" type="password" style="width: 300px; display: inline;" class="form-control" placeholder="Confirm Password">
            </div> 
            <div class="col-md-12" style="text-align:center;">
                <button :disabled="disable" style="background-color:#005baa; width: 300px; display:inline; " class="btn btn-primary" @click="haddleResetPassword()">
                    Submit
                </button>
            </div>
        </div> 
    </div>
</template>
<script>
import mixinAuth from "@/mixins/buyer/APIAuth";
import mixinAuth1 from "@/mixins/deliver/APIAuth";
import mixinAuth2 from "@/mixins/merchant/APIAuth";
export default {
    mixins: [
        mixinAuth,
        mixinAuth1,
        mixinAuth2
    ],
    data () {
        return {
            disable: true,
            userType: "",
            pass: "",
            resetObj: {
                _id: "",
                password: ""
            }
        }
    },
    created() {
        this.userType = window.location.href.split("&user=")[1];
        this.userTypeId = window.location.href.split("&user=")[0].split("password-reset?_id=")[1];
    },
    methods: {
        validatePassword(str) {
            if (this.resetObj.password !== "") {
                let val = this.resetObj.password.toLowerCase();
                let val1 = str.toLowerCase();
                if (val === val1) {
                    this.disable = false;
                } else {
                    this.disable = true;
                }
            }
        },
        haddleResetPassword: async function() {
            try {
                this.resetObj._id = this.userTypeId;
                if (this.userType === "user") {
                    await this.forgetPasswordSetFunctionBuyer(this.resetObj);
                } else if (this.userType === "deliver") {
                    await this.forgetPasswordSetFunctionDeliver(this.resetObj);
                } else if (this.userType === "merchant") {
                    await this.forgetPasswordSetFunctionMerchant(this.resetObj);
                }
                this.$swal.fire({
                    position: 'center',
                    type: 'success',
                    title: 'success change password',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.$router.push('/').catch(err => {});
            } catch (error) {
                this.$swal.fire({
                    position: 'center',
                    type: 'error',
                    title: 'error change password',
                    showConfirmButton: false,
                    timer: 1500
                })
                throw error;
            }
        }
    }
}
</script>